"use strict";

// Class definition
var KTSigninGeneral = function() {
    // Elements
    var form;
    var submitButton;
    var validator;

    // Handle form
    var handleForm = function(e) {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validator = FormValidation.formValidation(
			form,
			{
				fields: {
                    
					'useraccount': {
                        validators: {
                            /*
                            regexp: {
                                regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: 'The value is not a valid email address',
                            },
                            */
							notEmpty: {
								message: '請輸入 帳號名稱 或 Email'
							}
						}
					},
                    'userpassword': {
                        validators: {
                            notEmpty: {
                                message: '請輸入密碼'
                            }
                        }
                    } 
				},
				plugins: {
					trigger: new FormValidation.plugins.Trigger(),
					bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',  // comment to enable invalid state icons
                        eleValidClass: '' // comment to enable valid state icons
                    })
				}
			}
		);		

        // Handle form submit
        form.addEventListener('submit', function (e) {
            // Prevent button default action
            e.preventDefault();

            // Validate form
            validator.validate().then(function (status) {
                if (status == 'Valid') {
                    // Show loading indication
                    submitButton.setAttribute('data-kt-indicator', 'on');

                    // Disable button to avoid multiple click 
                    submitButton.disabled = true;
                    
                    // 將錯誤訊息框隱藏
                    $(".error-msg-box").css("display", "none");
                    $(".error-msg").text("");
                    
                    // axios 送出資料
                    var formData = new FormData(form);
                    axios.post( form.action, formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'X-CSRF-TOKEN': $('meta[name="X-CSRF-TOKEN"]').attr('content')
                        },
                    })
                    .then(response => {
                        console.log(response.data);
                        if(parseInt(response.data.error) != 0){
                            $(".error-msg-box").css("display", "flex");
                            $(".error-msg").text(response.data.msg);
                        }else{
                            var redirectUrl = form.getAttribute('data-kt-redirect-url');
                            if (redirectUrl) {
                                location.href = redirectUrl;
                            }
                        }
                    })
                    .catch(err => {
                        if(err.response){
                            switch(err.response.status){
                                case 422:
                                    $(".error-msg-box").css("display", "flex");
                                    $(".error-msg").text("送出的檔案格式錯誤。請重整後再試一次");
                                    //console.log("送出的檔案格式錯誤。", err.response);
                                    break;
                                case 403:
                                    $(".error-msg-box").css("display", "flex");
                                    $(".error-msg").text("畫面閒置過久。請重整後再試一次");
                                    //console.log("畫面閒置過久。", err.response);
                                    break;
                                case 404:
                                case 405:
                                    $(".error-msg-box").css("display", "flex");
                                    $(".error-msg").text("您欲查看的畫面不存在");
                                    //console.log("您欲查看的畫面不存在", err.response);
                                    break;
                            }
                        }else{
                            //console.log("網路連線錯誤，請重整畫面後再試一次", err);
                            $(".error-msg-box").css("display", "flex");
                            $(".error-msg").text("網路連線錯誤，請重整畫面後再試一次");
                        }
                    })
                    .finally(() => {
                        // Hide loading indication
                        submitButton.removeAttribute('data-kt-indicator');
                        // Enable button
                        submitButton.disabled = false;
                        return false;
                    });
                    
                    
                    
                    /*
                    // Simulate ajax request
                    setTimeout(function() {
                        // Hide loading indication
                        submitButton.removeAttribute('data-kt-indicator');

                        // Enable button
                        submitButton.disabled = false;

                        // Show message popup. For more info check the plugin's official documentation: https://sweetalert2.github.io/
                        Swal.fire({
                            text: "You have successfully logged in!",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        }).then(function (result) {
                            if (result.isConfirmed) { 
                                form.querySelector('[name="email"]').value= "";
                                form.querySelector('[name="password"]').value= "";  
                                                              
                                //form.submit(); // submit form
                                var redirectUrl = form.getAttribute('data-kt-redirect-url');
                                if (redirectUrl) {
                                    location.href = redirectUrl;
                                }
                            }
                        });
                    }, 2000);
                    */
                } else {
                    
                }
            });
		});
    }

    // Public functions
    return {
        // Initialization
        init: function() {
            form = document.querySelector('#sign_in_form');
            submitButton = document.querySelector('#sign_in_submit');
            
            handleForm();
        }
    };
}();

// On document ready
KTUtil.onDOMContentLoaded(function() {
    KTSigninGeneral.init();
});
